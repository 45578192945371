// If you want to override variables do it here
@import "variables";
// Import styles
@import "~@coreui/coreui/scss/coreui.scss";
// Temp fix for reactstrap
// If you want to add something do it here
@import "custom";
// ie fixes
@import "ie-fix";
$color-1: #808080;
$color-2: #FF6700;
$color-3: #5AC711;
$color-4: #0000FF;
$color-5: #7F00FF;
$color-6: #FF0000;

.blinking{
  animation:blinkingText 1.90s infinite;
  text-align: center;
}

@keyframes blinkingText{
  0%{     color: #ffc107;    }
  49%{    color: #ffc107; }
  60%{    color: transparent; }
  99%{    color:transparent;  }
  100%{   color: #ffc107;    }
}

body{
  background: #0b0d10 !important;
}

.styleimage {
  max-width: 850px;

}
.tv-margin {
  //margin-top: 2%;
}
.tv-photo {
  text-align: center;
}
.tv_logo{
  max-width: 75px;

}
.tv_logo2{
  max-width: 70px;
}

.tablespace{
  margin-top: 15px;
}
.mt12{
  margin-top: 35px;
}
.table100{
  width:100%;
}
.divTable{
  display: table;
  width: 100%;
  margin-top: 35px;
}
.divTableRow {
  display: table-row;
}
.divTableHead{
  text-align: center;
  color: black;
}
.divTableCell{
  text-align: center;
  color: black;
}
.divTableHeading {
  background-color: #EEE;
  display: table-header-group;
}
.divTableCell, .divTableHead {
  border: 1px solid #999999;
  display: table-cell;
  padding: 3px 10px;
}
.divTableHeading {
  background-color: #EEE;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
}
.divTable{
  h3,h4,h5{
    font-weight: bold;
    color: #1F1F1F;
  }
}
#root{

.notes {
 
    /* box-sizing: padding-box; */
    overflow:hidden;
}

  .app-body{
    overflow: hidden;
  }

  .page3{
    .card-footer{
      background:darkred;
    }
  }
  .dasboard-card{
    h5{
      color: rgba(11, 13, 16, 0.95);
    }
    h3{
      color: rgba(11, 13, 16, 0.95);
    }
    .card-header{
      background: #fff !important;
    }



  }
  .tv-section{
    background: #000;
  }

  .colbreak{
    page-break-inside: avoid;
    page-break-after: avoid;
  }
  .tv-news{
    color:#fff;
    background:#521212;
  }
  .tv_view_title{
    h2{
      color: #fff;
      padding: 10px 0;
      font-weight: 900;
    margin-bottom: 1rem;

    }
    text-align: center;
    background: #521212;
  }
  .tv_view_title2{
    h3{
      color: #fff;
      padding: 10px 0;
      font-weight: 900;
    // margin-bottom: 1rem;

    }
    text-align: center;
    background: #521212;
  }
  .tv_view_bottom {
    h2 {
     
      color: #fff;
      padding: 10px 0;
      font-weight: 900;
    }
    h4 {
      color: #fff;
      font-weight: 900;
      text-align: left;
    }
    .tv_logo
    {
      margin-right:10px;
    }
    .tv_logonews
    {
      margin-right:10px;
      width:20px;
      height: 10px;
    }
    text-align:center ;
  }

  .tv_view_bottom2 {
    h4 {
      color: #fff;
      font-weight: 900;
      text-align: right;
    }

  }
  .tv_view_bottom1 {
    h2 {
      color: #fff;
      font-weight: 900;
    }

  }


  marquee{
    padding: 8px 0px;
  }
  .tv-view{
    tr{
      font-size: 18px;
    }
    th, td{
      color: #fff;
      font-weight: 900;
      font-size: 28px;
    }
    tbody tr:nth-of-type(odd) {
      background-color: #000066;
    }
    tbody tr:nth-of-type(even) {
      background-color: #333399;
    }
    thead tr{
      background-color: #1C40D8;
    }
    tr{
      background-color: #fff;
    }
  }
}
.ckt{
  color:#6f1d1a;
}
.dash-title{
  color: #fff;
}
.login_section{
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #A9150F), to(#262626));
  // background-image: url("bg_img.png");
  // background-repeat: no-repeat;
  // background-position: right bottom;
}
.book-title{
  max-width: 550px;
}
.side_login {
  background-image: url("login_side.png");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #570501
;
}
.app-footer{
  background-image: -webkit-gradient(linear, left top, right bottom, color-stop(1%, #3A3A3A), to(#5B5858));
}
.modal-header{
  color: #fff;
  background-image: -webkit-gradient(linear, left top, right bottom, color-stop(1%, #A9150F), to(#262626)) !important;
}
#root .card .card-header h6{
  margin-bottom: 0px;
  padding: 5px 0px;
}
.modal-header .close{
  color: #fff;
}
.dataTables_filter{
  float:right;
}
.square{
  border-style:solid;
  border-width:2px;
  border-color:#bbbebf;;
  color: blue;
  padding:5px;
}
#root .breadcrumb{
  // opacity:0;
}
.inlineBlock{
  display: inline-block;
}
.mou-list li{
  display:inline-block;
}
#root{
  background-color: #0B0D10;
}
#root .badge-open {
  color: #fff;
  background-color:#808080 ;
}
#root .badge-warm {
  color: #fff;
  background-color:#FF6700 ;
}
.borderclass{
  border: none;
}
.clearfix{
  overflow: hidden;
}
.booklet
{
  background:url("../views/Checklist/frontpage_1.jpg") no-repeat;
  width:100%;
  height:1200px;
}

.booklet_last_page
{
  background:url("../views/Checklist/backpage.jpg") no-repeat;
  width:100%;
  height:1200px;
}
@media print {
  .header-fixed .app-body{
    margin-top: 0 !important;
  }
  @page {
    size: auto;   /* auto is the initial value */
    //  margin: 0;  /* this affects the margin in the printer settings */
    paddin-top:200px;
    background-color: #ffff;

  }

 .table  .card-header {
    background-image: -webkit-gradient(linear, left top, right bottom, color-stop(1%, #A9150F), to(#262626)) !important;
}


  footer{

    display: none;
  }

  .app-footer{
    display: none;
  }
  table { page-break-after:avoid;}
  tr    { page-break-inside:avoid;}
  td    { page-break-inside:auto;}
  thead { display:table-header-group; }
  .data_non_break{
    page-break-inside: avoid !important;
  }
  tr {
    page-break-inside: avoid !important;
  }
  #root {
    background-color: #fff;
    button{
      display: none;
    }
    .form-control:focus {
      border: none;
    }
    .form-control {
      border:none;
    }
    .page-margin{
      margin-top: 20px;
    }
  }
  .page-break{
    page-break-after: always;
    page-break-inside: avoid;
  }
}
.heading-design
{
  height:550px;
  background-color:#a9150f;
  margin-bottom: 10px;;
}
.head-colors
{
  text-align: center;
  color:white;
  h1{
    font-weight: 900;
    position: relative;
    top: 222px;
  }
}
.title3{
  margin-left: 40px;
}
.index_name{
  margin: 0 auto;
  width: 1500px;
}
.index_name ul li{
  font-size: 25px;
  margin-bottom: 15px;
}
.index_name {
  padding: 50px 0px;
}
.index_name ul {
  list-style: none;
}
.title2{
  font-size: 60px;
  text-align: center;
  margin: 0 auto;
  position: relative;
  top:50%;
  color: #fff;
}
.title23{
  font-size: 60px;
  text-align: center;
  margin: 0 auto;
  position: relative;
  top:50%;
  color:#23282c;
}
.title6{
  font-size: 45px;
  text-align: center;
  margin-top: 10px;
  position: relative;
  top:50%;
  color: #fff;
}
.title63{
  font-size: 45px;
  text-align: center;
  margin-top:10px;
  position: relative;
  top:43%;
  color:#23282c;
}
.title64{
  font-size: 30px;
  text-align: center;
  margin-top: 10px;
  position: relative;
  top:50%;
  color: #fff;
}
.page2{
  background-color:#464c52;
  height: 1400px;
}
.page23{
  background-color:#e9ecef;
  height: 1400px;
}
.page-break{
  page-break-after: always;
  page-break-inside: avoid;
}
.panel-title{
  font-size: 26px;
  position: relative;
  bottom: 658px;
  left: 107px;
  color: #fff;
}
.book-title {
  font-size: 35px;
  position: relative;
  bottom: 657px;
  left:108px;
  color: #fff;
}
.card-margin {
  margin-top: 40px;
}

#root .badge-hot {
  color: #fff;
  background-color:#5ac711 ;
}
#root .badge-closed {
  color: #fff;
  background-color:#0000FF ;
}
#root .badge-hold {
  color: #fff;
  background-color:#7F00FF ;
}
#root .badge-cold {
  color: #fff;
  background-color:#FF0000 ;
}
// .addproject {
//   background-color:#0000FF ;
// }
.notification-list{
  margin-left: 20px;
  margin-top: 20px;
}
.sidebar .nav-item {
  border-bottom: 1px solid #FFFFFF36;
}
.sidebar .nav-item ul li:first-child {
  border-top:1px solid #fff;
}
#root .card  .Ld .card-header {
  background-color:#20a8d8;
  color:#fff;
}
.pro-table th, .table td {
  padding: 0.75rem;
  vertical-align: middle !important;
  border-top: 1px solid #C8CED3;
}
#root .card .react-select__control{
  color: #000;
}
#root .card  .Ld .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(101, 101, 101, 0.19)
}
.sp{
  padding: 0 !important;
  margin: 0 !important;
}
.flb{
  margin-left: 25px;
}
.app-body .sidebar {
  color: #fff;
  // background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #048490), to(#224ABE));
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #A9150F), to(#262626));
}
.sidebar .nav-link .nav-icon {
  color: #FFFFFF;
}
.sidebar .nav-link.active {
  color: #fff;
  background: #5D6567;
}
.sidebar .nav-link:hover {
  color: #fff;
  background: #5D6567;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(101, 101, 101, 0.19);
}
body #root {
  color:#047aa3;
}
body #root {
  color:#047aa3;
}
body #root .card .card-header{
  color:#fff;
  padding: 0.15rem 1.25rem;
}
body #root .card .card-header button{
  margin-top: 4px
}
body #root .card .card-header .btn-primary {
  color: #FFFDFD;
  background-color: #455D2A;
  border-color: #535353;
}
body #root  .btn-primary {
  color: #fff;
  background-color: #5A5B5B;
  border: 2px solid silver;
}
body #root .card label{
  color:#000;
}
.icon-r .fa{
  color: #fff;
}
.app-header {
  // background-image: linear-gradient(to bottom right,  #528695, #1B234D);
  background-image: -webkit-gradient(linear, left top, right bottom, color-stop(1%, #3A3A3A), to(#5B5858));
  border-bottom: 1px solid #A1A1A1;
}
.navbar-nav .nav-link {
  color: #F70606;
}
.navbar-nav .nav-link:hover, .navbar-nav .nav-link:focus {
  color: #00FFD0;
}
.form-control{
  border: 1px solid #04A6D3;
}
#root .card .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-image: -webkit-gradient(linear, left top, right bottom, color-stop(1%, #A9150F), to(#262626));
}
th.sortable {
  color: #4993CE;
}
.side-p{
  margin-bottom:25px;
}
.lsp{
  margin-left: 20px;
}
.subnotif
{
  height:60px;
  background-color: grey;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.subnotif2
{
  height:60px;
  background-color: lightblue;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.notif
{
  margin-left: 5px;
  margin-right:5px ;
}
.custom-radios {
  div {
    display: inline-block;
  }
  input[type="radio"] {
    display: none;
    + label {
      color: #333;
      font-family: Arial, sans-serif;
      font-size: 14px;
      span {
        display: inline-block;
        width: 40px;
        height: 40px;
        margin: -1px 4px 0 0;
        vertical-align: middle;
        cursor: pointer;
        border-radius: 50%;
        border: 2px solid #FFFFFF;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
        background-repeat: no-repeat;
        background-position: center;
        text-align: center;
        line-height: 44px;
        img {
          opacity: 0;
          transition: all .3s ease;
        }
      }
    }
    &#color-1 + label span {
      background-color: $color-1;
    }
    &#color-2 + label span {
      background-color: $color-2;
    }
    &#color-3 + label span {
      background-color: $color-3;
    }
    &#color-4 + label span {
      background-color: $color-4;
    }
    &#color-5 + label span {
      background-color: $color-5;
    }
    &#color-6 + label span {
      background-color: $color-6;
    }
    &:checked + label span img {
      opacity: 1;
    }
  }
}
// toggle switch
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 20px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:#bababa;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 2px;
  background-color:white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color:#455d2a ;
}
body #root input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 50px;
}
.slider.round:before {
  border-radius: 55%;
}
.main-notification
{
  .buttonclassname{
    button{
      display: inline;
      min-width:200px;
    }
    .active-btn{
      border: none;
      background-color:#fff;
      strong{
        color:#2eb82e;
      }
    }
    .not-active{
      background-color:#fff;
      border-width: thin;
      border-top: none;
    }
    width:100%;
  }
}
// toggle switch



//Akhil A S | Styles updates 18 Feb 2020

.bookletPrint{
  .booklet_last_page{
    height: 1430px;
    background-size: cover;
  }
  .booklet{
    margin-bottom: 0;
    height: 1430px;
    background-size: cover;
  }
  .card {
    margin-bottom: 0;
  }
  .card-margin{
    margin-top: 0;
  }
  .table{
    margin-bottom: 0;
  }
  .card-body{
    padding: 0;
  }
  textarea,
  .is-touched.is-pristine.av-valid.form-control{
    overflow: hidden;
  }
  .no-border{
    border: none !important;
  }
  .card-header{
    padding-top: 12px !important;
  }
  .page2,
  .page23{
    height: 1430px;
  }
  .divTable{
    margin-top: 0;
  }
}
#root{
      		    color: #047aa3;
    width: 100%;
    float: left;
    max-width: 100%;
    overflow: hidden;
      	}
		#root .tv_view_title h2 {
			font-size: calc(20px + 2vh);
		}
	
		#root .tv-view th{
			font-size: calc(22px + 1vh);
		}
		#root .tv-view td{
			height: 9vh;
			padding: 5px 15px;
			font-size: calc(22px + 1vh);
		}
		#root .tv-view td:last-child{
			white-space: nowrap;
		}

      	#root .tv_view_title h2 {
		    margin-bottom: 0;
		}
		#root .tv-news {
		    position: fixed;
		    bottom: 0;
		    z-index: 999;
		    width: 100%;
		    float: left;
		    min-height: 50px;
		    margin-bottom: 0;
		}
 @media print {
         .app-header {
           display:none;
         }
      }
